





































































import { Component, Vue } from "vue-property-decorator";
import PrintableSearchResults from "@/components/PrintableSearchResults.vue";
import StateAbbreviationToName from "@/assets/ts/stateAbbreviationToName";
import RequestHandler from "@/assets/ts/requestHandler.ts";

const RH = new RequestHandler();
const stateAbbreviationToName = new StateAbbreviationToName();

@Component({
  components: {
    PrintableSearchResults
  }
})
export default class NoResults extends Vue {
  private vehicle: any;
  private searchID: any;
  private stolen = false;
  private nmvtisState = "";
  private message = "";

  get searchDateTime(): string {
    const searchData = localStorage.getItem("resultVehicle");
    const parsedData = JSON.parse(searchData ? searchData : "");
    return (
      new Date(parsedData.searchDate).toLocaleDateString() +
      " " +
      new Date(parsedData.searchDate).toLocaleTimeString()
    );
  }

  private printResults() {
    window.print();
  }

  async created() {
    this.$root.$emit("stop-loading-overlay");
    this.vehicle = this.$route.params.vehicle;
    this.searchID = this.$route.params.searchID;
    this.stolen = this.$route.params.stolen == "Stolen";
    this.nmvtisState = stateAbbreviationToName.convertToStateName(
      this.$route.params.nmvtis
    );
    if (!this.searchID) {
      this.message = "Please search from the search page";
    } else if (this.stolen) {
      this.message =
        "The Tennessee Department of Revenue, Vehicle Services Division, attempted to process your request for verification of ownership. During our search we determined this vehicle is stolen. A representative from the Vehicle Services Division will be in contact with you.";
    } else if (this.nmvtisState != "") {
      this.message =
        "The Tennessee Department of Revenue, Vehicle Services Division, attempted to process your request for verification of ownership. After conducting a diligent search, we determined that newer information for this VIN has been found in another State. Please contact the motor vehicle agency for " +
        this.nmvtisState +
        ".";
    } else {
      this.message =
        "The Tennessee Department of Revenue, Vehicle Services Division, attempted to process your request for verification of ownership. After conducting a diligent search, we determined that no records were found for this motor vehicle.";
    }
  }
}
