













































































































































































































































































































































































































































































































































































import { Vehicle } from "@/assets/classes/Vehicle.ts";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PrintableSearchResults extends Vue {
  @Prop() resultVehicle!: Vehicle;
  @Prop({ default: "" }) searchText!: string;
  @Prop({ default: false }) message!: string;
  @Prop({ default: false }) searchDateTime!: string;
  get numOfOwners(): number {
    return this.resultVehicle && this.resultVehicle.owners
      ? this.resultVehicle.owners.length
      : 0;
  }
  get numOfLessees(): number {
    return this.resultVehicle && this.resultVehicle.lessees
      ? this.resultVehicle.lessees.length
      : 0;
  }
  get numOfLienholders(): number {
    return this.resultVehicle && this.resultVehicle.lienholders
      ? this.resultVehicle.lienholders.length
      : 0;
  }
  get numOfStops(): number {
    return this.resultVehicle && this.resultVehicle.stops
      ? this.resultVehicle.stops.length
      : 0;
  }
  get ownerCols(): number {
    return this.numOfOwners > 1 ? 6 : 12;
  }
  get lesseeCols(): number {
    return this.numOfLessees > 1 ? 6 : 12;
  }
  get lienholderCols(): number {
    return this.numOfLienholders > 1 ? 6 : 12;
  }
  get stopCols(): number {
    return this.numOfStops > 1 ? 6 : 12;
  }
}
